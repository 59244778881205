import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { connect } from 'react-redux'
import { history } from '../store'
import Spinner from '../components/Spinner'
import App from '../components/App'
import LoginForm from '../components/LoginForm'
import Search from '../components/Search'
import Order from '../components/Order'
import Orders from '../components/Orders'
import Done from '../components/Done'

const PrivateRoute = ({ component: Component, authenticated, ...rest }) => (
  <Route {...rest} render={(props) => (
      authenticated === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
  )

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
  mode: state.rma.mode,
  order: state.rma.order,
  orders: state.rma.orders,
  done: state.rma.done,
})

class RoutesComponent extends React.Component {
  loadComponent = () => {
    const {
      authenticated,
      order,
      orders,
      done
    } = this.props
    if(!authenticated) return LoginForm
    if(order){
      if (done){
        return [Done, 'Done']
      }
      return [Order, 'Order']
    } else if (orders){
      return [Orders, 'Orders']
    }
    return [Search, 'Search']
  }
  render() {
    const {authenticated} = this.props
    const view = this.loadComponent()
    const viewName = view[1]
    return (
      <ConnectedRouter history={history}>
        <App view={viewName}>
          <Spinner/>
          <Route path="/login" component={LoginForm}/>
          <PrivateRoute exact path={["/:code?", "/"]} authenticated={authenticated} component={view[0]}/>
        </App>
      </ConnectedRouter>
    )
  }
}
const Routes = connect(mapStateToProps)(RoutesComponent)

export default Routes
